import React from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faClock, faHome, faPhotoVideo} from "@fortawesome/free-solid-svg-icons";

class Header extends React.Component {
  checkNavLinkIsActive = (props: { isActive: boolean }): string => {
    return props.isActive ? 'selected' : '';
  }

  render() {
    /**
     * <NavLink to="/noticias" className={this.checkNavLinkIsActive}>
     * <FontAwesomeIcon icon={faNewspaper}></FontAwesomeIcon>
     * </NavLink>
     */
    return (
      <nav className="navbar-bottom">
        <NavLink to="/" className={this.checkNavLinkIsActive}>
          <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
        </NavLink>

        <NavLink to="/horarios" className={this.checkNavLinkIsActive}>
          <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
        </NavLink>

        <NavLink to="/contacto" className={this.checkNavLinkIsActive}>
          <FontAwesomeIcon
            icon={faAddressBook}
          ></FontAwesomeIcon>
        </NavLink>

        <NavLink to="/galeria" className={this.checkNavLinkIsActive}>
          <FontAwesomeIcon
            icon={faPhotoVideo}
          ></FontAwesomeIcon>
        </NavLink>
      </nav>
    );
  }
}

export default Header;
