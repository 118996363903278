import React, {useState} from 'react';
import {Carousel} from 'react-bootstrap';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';

interface Image {
    src: any;
    alt: string;
}

declare const require: {
    context(
        directory: string,
        useSubdirectories?: boolean,
        regExp?: RegExp
    ): {
        keys(): string[];
        <T>(id: string): T;
    };
};

const Galeria: React.FC = () => {
    const [images, setImages] = useState<Image[]>([]);

    // Cargar las imágenes
    useState(() => {
        const requireImages = require.context(
            '../assets/images/gallery',
            true,
            /\.(png|jpe?g|svg)$/,
        );

        const imageKeys = requireImages.keys();
        const loadedImages: Image[] = [];

        imageKeys.forEach((key) => {
            const image = {
                src: requireImages(key),
                alt: key.substring(key.lastIndexOf('/') + 1),
            };

            loadedImages.push(image);
        });

        setImages(loadedImages);
    });

    return (
        <Carousel prevIcon={<BsChevronLeft/>} nextIcon={<BsChevronRight/>} className="full-height w-100">
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <img className="d-block" src={image.src} alt={image.alt}/>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default Galeria;