import {
    faCodeBranch,
    faEnvelope,
    faMapMarkedAlt,
    faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactDOM from "react-dom";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const position_sebastian = [40.7428242, -4.056033, 20.56];
const position_carmen = [40.7409382, -4.0561993, 20.69];
const position_ermita_sta_maria = [40.7314079, -4.0618716, 16.09];
const position_nieves = [40.7840346, -4.0055046, 19.69];
const position_center = [40.7371382, -4.0571293, 20.69];
const position_safa = [40.7559532, -4.049559, 17];
const position_fuenfria = [40.7598637, -4.0749564, 18.92];
const position_antonio = [40.7440133, -4.0448309, 17];
const posicion_emaus = [40.7419187, -4.056565, 139];

function Contacto() {
    return (
        <div className="full-height contacto">
            <h1 className="card-title">Contacto</h1>
            <div className="flex-row">
                <div className="column">
                    <h2 className="card-subtitle">
                        Iglesia del Carmen (despacho parroquial)
                    </h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/F9quwFVJZQoFHpVu6"
                        >
                            Travesía del Carmen 4, 28470 Cercedilla, Madrid
                        </a>
                    </h3>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faPhoneAlt} />
                        <a href="tel:918520414">91 852 04 14</a>
                    </h3>
                    <br />
                    <h2 className="card-subtitle">Iglesia de San Sebastián</h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/41ysX53jj6dTd4Lo9"
                        >
                            Calle de la iglesia, s/n; 28470 Cercedilla, Madrid
                        </a>
                    </h3>
                    <br />
                    <h2 className="card-subtitle">Ermita de Santa María</h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/RhfBZBj4ZsoeLtN77"
                        >
                            Camino de Sta. María, 14A, 28470 Cercedilla, Madrid
                        </a>
                    </h3>
                    <br />
                    <h2 className="card-subtitle">Hospital de la Fuenfría</h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/QqFmc1kiKDatJYZR6"
                        >
                            Ctra. de las Dehesas, 0, 28479 Las Dehesas, Madrid
                        </a>
                    </h3>
                    <br />
                </div>
                <div className="column">
                    <h2 className="card-subtitle">
                        Nuestra Señora de las Nieves
                    </h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/w3zcjW3efBEMuHLd8"
                        >
                            C. Vitoria, 4, 28470 Cercedilla, Madrid
                        </a>
                    </h3>
                    <br />
                    <h2 className="card-subtitle">
                        Nuestra Señora de las Nieves
                    </h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/3MbeJMv8XxKKaLRs5"
                        >
                            Cam. de la Capilla, 3, 28479 Cercedilla, Madrid
                        </a>
                    </h3>
                    <br />
                    <h2 className="card-subtitle">Ermita de San Antonio</h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/uinYetHpcKcqXGqEA"
                        >
                            C. de las Puentes, 215, 28470 Cercedilla, Madrid
                        </a>
                    </h3>
                    <br />
                    <h2 className="card-subtitle">Casa de Emaús</h2>
                    <h3 className="card-subtitle">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <a
                            target="_new"
                            href="https://goo.gl/maps/q7xKvfnLXD43gU467"
                        >
                            C. Carrera del Señor, esquina con C. de los
                            Registros, 28470 Cercedilla, Madrid.
                        </a>
                    </h3>
                    <br />

                    <h2 className="card-subtitle">Administración (web)</h2>
                    <h3 className="card-subtitle">
                        <p>
                            <FontAwesomeIcon icon={faCodeBranch} />
                            <a target="_new" href="https://thenutsycompany.com">
                                The Nutsy company
                            </a>
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <a
                                target="_new"
                                href="mailto:admin.parroquia@thenutsycompany.com"
                            >
                                Enviar un mail al administrador
                            </a>
                        </p>
                    </h3>
                </div>
            </div>
            <br />
            <div className="card-body">
                Mapa de la zona:
                <MapContainer
                    center={position_center}
                    zoom={14}
                    scrollWheelZoom={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position_sebastian}>
                        <Popup>Iglesia de San Sebastián.</Popup>
                    </Marker>
                    <Marker position={position_carmen}>
                        <Popup>Iglesia del Carmen.</Popup>
                    </Marker>
                    <Marker position={position_ermita_sta_maria}>
                        <Popup>Ermita de Sta María.</Popup>
                    </Marker>
                    <Marker position={position_nieves}>
                        <Popup>Nuestra Señora de las Nieves</Popup>
                    </Marker>
                    <Marker position={position_safa}>
                        <Popup>Sagrada Familia (Colonia Camorritos)</Popup>
                    </Marker>
                    <Marker position={position_fuenfria}>
                        <Popup>Hospital de la Fuenfría</Popup>
                    </Marker>
                    <Marker position={position_antonio}>
                        <Popup>Ermita de San Antonio</Popup>
                    </Marker>
                    <Marker position={posicion_emaus}>
                        <Popup>Casa Emaús</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    );
}

export default Contacto;

if (document.getElementById("contacto")) {
    ReactDOM.render(<contacto />, document.getElementById("contacto"));
}
