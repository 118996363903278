import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/App.scss"
import Header from "./components/Header";
import Main from "./components/Main";
import Horarios from "./components/Horarios";
import Contacto from "./components/Contacto";
import Galeria from "./components/Galeria";

function App() {
  return (
    <BrowserRouter>
      <div id="content">
        <Routes>
          <Route path="/" element={<Main/>}/>
          <Route path="/horarios" element={<Horarios/>}/>
          <Route path="/contacto" element={<Contacto/>}/>
          <Route path="/galeria" element={<Galeria/>}/>
        </Routes>
      </div>
      <Header/>
    </BrowserRouter>
  );
}

export default App;
